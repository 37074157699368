.social-bar {
  background-color: #000;
  top: 0;
  width: 100%;
  height: $social-bar-height; 
  text-align: end;
  a {
      padding: 16px;
      color: white;
      font-size: $social-bar-height - 10;
  }
}

.Navbar-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Navbar-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Navbar-header {
  display: flex;
  flex-direction: row;
  color: black;
  justify-content: space-between
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header__fixed {
  top: 0px !important;
  position: sticky;
}

.header {
  width: 100%;
  background-color: #fff;
  height: 80px;
  top: $social-bar-height;
  z-index: 10;
  box-shadow: $box-shadow-bottom;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
    position: relative;
    height: 80px;
    @include tablet-portrait {
      width: 100%;
    }
  }
  &__logo {
    position: absolute;
    left: 0;
    @include tablet-portrait {
      left: 10px;
    }
    img {
      height: 70px;
      width: auto;
      animation: App-logo-spin infinite 20s linear;
    }
  }
  nav {
    display: flex;
    margin: 0;
    @include animation();
    @include tablet-portrait {
      flex-direction: column;
      width: 100%;
      text-align: center;
      position: absolute;
      top: -200vh;
      background-color: $color-white;
      padding: 50px 0;
      &.active {
        top: 0;
      }
    }
    a {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.6px;
      line-height: 20px;
      position: relative;
      text-transform: uppercase;
      color: $color-black;
      &:before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        height: 2px;
        width: 100%;
        background: #ccc;
        transform: scaleX(0);
        transform-origin: left;
        transition: 0.3s transform;
        @include tablet-portrait {
          content: none;
        }
      }
      &:hover {
        color: $color-black;
        &:before {
          transform: scaleX(1);
        }
      }
      &:last-child {
        position: absolute;
        right: 0;
        color: $color-grey;
        &:hover {
          color: $color-black;
        }
        @include tablet-portrait {
          position: relative;
          margin-top: 30px;
        }
      }
    }
  }
  &__menu {
    position: absolute;
    display: none;
    right: 20px;
    @include tablet-portrait {
      display: block;
    }
  }
  &__open {
    display: none;
    cursor: pointer;
    img {
      width: 30px;
      height: auto;
    }
    &.active {
      display: block;
    }
  }
  &__close {
    display: none;
    cursor: pointer;
    img {
      width: 30px;
      height: auto;
    }
    &.active {
      display: block;
    }
  }
}