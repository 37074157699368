.Team-container {
  margin-top: 30px;
}

.Team-about {
  padding: 0;
}

.TeamMember {
  list-style-type: none;
  padding: 0;
  flex: 1 1 0px;
  img {
    width: 50%;
  }
  &__info {
    text-transform: uppercase;
  }
}
