* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  font-family: "Fira Sans", sans-serif;
  scroll-behavior: smooth;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 100%;
  font-family: $font;
  scroll-behavior: smooth;
  min-width: 320px;
  -webkit-font-smoothing: antialiased;
  &.no-scroll {
    overflow: hidden;
  }
}

input {
  width: 100%;
  font-family: "Fira Sans", sans-serif;
  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
}

textarea {
  width: 100%;
  resize: none;
  font-family: "Fira Sans", sans-serif;
  &:active,
  &:focus,
  &:hover {
    outline: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
  @include animation;
}

p {
  font-size: 16px;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-left: 24px;
}

button {
  outline: none;
  cursor: pointer;
}

strong {
  font-weight: bold;
}

.flex {
  display: flex;
}

.flex-align {
  display: flex;
  align-items: center;
}

.flex-justify {
  display: flex;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-relative {
  width: 100%;
  height: 100%;
  position: relative;
}

.position-fixed {
  position: fixed;
  height: 100%;
  width: 100%;
}

section {
  overflow: hidden;
  position: relative;
  z-index: 0;
  padding-bottom: 40px;
  &.section-detail {
    padding-top: 40px;
  }
  h2 {
    margin: 20px 0;
  }
}

.watermark {
  background-image: url("../assets/images/bicycle-kick-logo-water.svg");
  background-size: 60%;
  background-repeat: repeat-y;
  background-position: top;
}

.padding-0 {
  padding: 0;
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}
