.footer {
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  font-size: calc(5px + 1vmin);
  color: $color-white;
  list-style: none;
  box-shadow: $box-shadow-top;
  background-color: $color-grey;
  &__member {
    padding: 10px;
    text-align: center;
    margin: 20px 0;
    img {
      border-radius: 50%;
      margin-bottom: 20px;
      box-shadow: $box-shadow-bottom;
    }
  }
}
