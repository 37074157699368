@mixin animation($speed: .2s) {
  transition: all $speed linear;
}

@mixin zoom($zoom: .02) {
  transform: scale($zoom);
}

@mixin desktopxl {
  @media (max-width: 1500px) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin tablet-landscape {
  @media (max-width: 992px) {
    @content;
  }
}
@mixin tablet-portrait {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin phone {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin phonexs {
  @media (max-width: 350px) {
    @content;
  }
}