.TransferCardShadow {
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.TransferCardShadow:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.TransferCard {
  list-style-type: none;
  display: flex;
  &--compactwrapper {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &__clubinfo {
    padding-top: 10px;
    text-align: center;
  }
  &__image {
    width: 30%;
    height: 100%;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__compactimage {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.TransferInfo {
  width: 70%;
}

.TransferListFilter {
  .switcher {
    margin: 20px 0;
  }
  .switch {
    color: $color-grey;
    @include animation();
    position: relative;
    font-size: 16px;
    text-transform: uppercase;
    padding: 0 5px;
    &:before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 2px;
      width: 100%;
      background: #ccc;
      transform: scaleX(0);
      transform-origin: left;
      transition: 0.3s transform;
    }
    &:hover {
      color: $color-black;
      &:before {
        transform: scaleX(1);
      }
    }
}
}