.App {
  max-width: 1000px;
}

@include media-breakpoint-up(md) {
  .Section-with-vertical-title {
    margin-left: 2vw;
    margin-right: 11vw;
  }
}

header a {
  color: #fff;
}

nav a {
  margin: 10px;
  color: #000;
}

a:link {
  text-decoration: none;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: row;
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Counters {
  text-align: center;

  &__number {
    @include font-size(7vw);
    font-weight: bold;
  }

  &__text {
    font-size: 2vw;
  }
}

.VerticalTitle {
  visibility: collapse;
}

@include media-breakpoint-up(md) {
  .VerticalTitle {
    visibility: visible;
    position: fixed;
    bottom: 0;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-weight: 600;
    text-transform: uppercase;
    color: #a7a7a7;
    z-index: -100;
    left: 86vw;
    font-size: 10vw;
  }
}

.LogoBadge {
  &__CompanyName {
    color: white;
    font-weight: bold;
    font-size: 15vw;

    @include media-breakpoint-up(md) {
      font-size: 4vw;
    }
  }

  &__CompanySubtitle {
    color: white;
    font-weight: bold;
    font-size: 10vw;

    @include media-breakpoint-up(md) {
      font-size: 2vw;
      letter-spacing: 0.3vw;
    }
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
