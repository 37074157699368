.highlight {
  text-align: left;
  list-style-type: none;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 30px;
  padding-left: 1vw;
  padding-right: 1vw;
  @include phone {
    width: 80%;
  }
  text-align: center;
  &__title {
    margin-bottom: 30px;
    font-size: 3rem;
  }
  &__text {
  }
}
.Homepage-highlights {
  .swiper-button-prev {
    color: $color-white;
    left: 0;
  }
  .swiper-button-next {
    color: $color-white;
    right: 0;
  }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 0;
  }
}