.banner {
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    z-index: 5;
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    visibility: visible;
    background-color: rgba(28, 28, 28, .2);
  }
  &__title {
    font-size: 5em;
    color: #ddd;
    filter: drop-shadow(0 0 0.75rem black);
    @include phone {
      font-size: 3em;
      padding: 0 20px;
    }
  }
}