.TransferCard__clubinfo {
  .ClubImage {
    width: 50%;
    filter: none;
    -webkit-filter: none;
    margin-right: 8px;
  }
}

.TransferCard--wrapper {
  .ClubImage {
    width: 80%;
    filter: none;
    -webkit-filter: none;
    margin-right: 8px;
  }
}

.PlayerCard__team {
  .ClubLogoWrapper {
    display: inline-block;
  }
  .ClubNameWrapper {
    display: inline-block;
  }
  .ClubImage {
    width: 20px;
    filter: none;
    -webkit-filter: none;
    margin-right: 8px;
  }
}

.PlayerInfo {
  .ClubLogoWrapper {
    display: inline-block;
  }
  .ClubNameWrapper {
    display: inline-block;
  }
  .ClubImage {
    width: 20px;
    filter: none;
    -webkit-filter: none;
    margin-right: 8px;
  }
}