.Player-list-outer-container {
  display: flex;
  justify-content: flex-start;
  svg {
    min-width: 200px;
    max-width: 400px;
    text {
      font-size: 11px;
      letter-spacing: -0.2px;
      font-weight: 500;
    }
    padding-bottom: 50px;
  }
}

.Player-list-container {
  display: flex;
}

.PlayerListGroup {
  margin-bottom: 30px;
}

.PlayerListGroup__Title {
  color: $color-grey;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.switch {
  cursor: pointer;
}

.PlayerCard {
  list-style-type: none;
  padding: 16px;
  .PlayerCardImage {
    border-radius: 50%;
    width: 80px;
    border: 2px solid $color-grey;
    filter: none;
    -webkit-filter: none;
    @include animation();
    &:hover {
      filter: gray;
      -webkit-filter: grayscale(1);
    }
    object-fit: cover;
  }
  &__name {
    color: $color-black;
    font-size: 18px;
  }
  &__team {
    color: $color-black;
  }
}

.PlayerInfo {
  padding: 2px 16px;
  h4 {
    font-weight: bold;
    text-transform: uppercase;
  }
  .switcher {
    margin: 20px 0;
  }
  .switch {
    color: $color-grey;
    @include animation();
    position: relative;
    font-size: 16px;
    text-transform: uppercase;
    padding: 0 5px;
    &:before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 2px;
      width: 100%;
      background: #ccc;
      transform: scaleX(0);
      transform-origin: left;
      transition: 0.3s transform;
    }
    &:hover {
      color: $color-black;
      &:before {
        transform: scaleX(1);
      }
    }
  }
}

.HeroPlayers {
  background: rgb(9,9,121);
  background: linear-gradient(270deg, rgba(9,9,121,1) 53%, rgba(0,212,255,1) 74%, rgba(9,9,121,1) 93%);
  .ClubImage {
    width: 40px;
    filter: none;
    -webkit-filter: none;
    margin-right: 10px;
  }
}

.HeroPlayer {
  margin-bottom: 50px;
  color: $color-white;
  font-size: 25px;
  font-weight: bold;
  &__name {
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.swiper-container { width: 100%; }