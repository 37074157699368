// .Scouting-container {
//   display: flex;
//   flex-direction: column
// }

// .ScoutingItem {
//   list-style-type: none;
// }

.ScoutingItem {
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 20px 0;
}

.Scouting-date {
  font-size: .75rem;
  color: #546E7A;
  padding-bottom: 20px;
}

.Scouting-world {
   margin-bottom: 20px;
}

// ROFL https://bugs.chromium.org/p/chromium/issues/detail?id=352912
#world > a:visited {
  fill: inherit;
  stroke: inherit;
}

path[data-country-scouted='true'] {
  fill: rgb(7, 109, 198);
  &:hover {
    fill: rgb(87, 109, 198);
    cursor: pointer;
  }
}

.ThumbnailsWrapper {
  position: relative;
  color: white
}

.CenteredThumbnailText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em
}
